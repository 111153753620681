import './PageTopBar.css';

interface PageTopBarProp {
  content: any;
}

export default function PageTopBar({content}: PageTopBarProp) {

  return (
    <div className="page-top-bar">
      <h1>{content}</h1>
    </div>
  );

}